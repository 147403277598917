<div *transloco="let t" class="pt-m pl-sm pr-sm pb-ml round-card">

  <app-avatar class="avatar-center" />

  <h2 class="mb-xs">{{ userData?.firstName }} {{ userData?.lastName }}</h2>

  <h5 class="mb-m mt-0">{{ userData?.attributes?.title }}</h5>

  <div class="d-flex justify-content-start border-bottom" *ngIf="userData?.attributes?.organisation">
    <mat-icon class="material-icons">local_hospital</mat-icon>
    <span class="ml-sm">{{ userData?.attributes?.organisation }}</span>
  </div>
  <div class="d-flex justify-content-start border-bottom" *ngIf="userData?.attributes?.department">
    <mat-icon class="material-icons">park</mat-icon>
    <span class="ml-sm">{{ userData?.attributes?.department }}</span>
  </div>

    @if (userData?.sites?.length) {
        <div class="left-align mb-l">
            <h5 class="mb-0">{{ t('organisations.title') }}</h5>
            @for (site of userData?.sites; track $index) {
                @switch (site?.directAssignment) {
                    @case (true) {
                        <div class="border-bottom pt-xs d-flex">
                            <div [ngClass]="{'left-align': site!.units!.length }"
                                 class="truncate-text clickable font-l"
                                 [matTooltip]="site!.name">{{ site?.name }}
                            </div>
                            @if (site!.units!.length) {
                                <cx-menu icon="badge"
                                         [matTooltip]="t('organisations.unitsTooltip')"
                                         class="justify-content-end ml-xs">
                                    @for (unit of site?.units; track $index) {
                                        <div class="d-flex m-xs font-l">
                                            <span>{{ unit?.name }}</span>
                                        </div>
                                    }
                                </cx-menu>
                            }
                        </div>
                    }
                    @case (false) {
                        @for (unit of site?.units; track $index) {
                            <div class="border-bottom pt-xs">
                                <div
                                        class="truncate-text clickable font-s"
                                        [matTooltip]="site!.name">{{ site?.name }}
                                </div>
                                <div class="font-l">
                                    <span>{{ unit?.name }}</span>
                                </div>
                            </div>
                        }
                    }
                }
            }
        </div>
    }

  <div class="links mb-s">
    <a href="https://www.bbraun.com/en/privacy-policy.html" target='_blank' class="small-link"
    >{{ t('links.privacyPolicy') }}</a
    >
    <a
      data-cy="link-terms-of-use"
      class="link small-link small-link mr-s ml-s"
      target="_self"
      (click)="goToTermsOfUse()"
    >{{ t('links.termsOfUse') }}</a
    >
    <a href="https://www.bbraun.com" target='_blank' class="small-link"
    >{{ t('links.cookiePolicy') }}</a
    >
  </div>
</div>
